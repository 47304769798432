@charset "UTF-8";
@font-face {
  font-family: FilsonPro;
  src: url("../fonts/font-english-bold.woff");
  src: url("../fonts/font-english-bold.woff") format("woff"), url("../fonts/font-english-bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: FilsonPro;
  src: url("../fonts/font-english-regular.woff");
  src: url("../fonts/font-english-regular.woff") format("woff"), url("../fonts/font-english-regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: Russian;
  src: url("../fonts/font-russian-bold.woff");
  src: url("../fonts/font-russian-bold.woff") format("woff"), url("../fonts/font-russian-bold.woff") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: Russian;
  src: url("../fonts/font-russian-regular.woff");
  src: url("../fonts/font-russian-regular.woff") format("woff"), url("../fonts/font-russian-regular.woff") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: Spain;
  src: url("../fonts/font-spain-bold.woff");
  src: url("../fonts/font-spain-bold.woff") format("woff"), url("../fonts/font-spain-bold.woff") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: Spain;
  src: url("../fonts/font-spain-regular.woff");
  src: url("../fonts/font-spain-regular.woff") format("woff"), url("../fonts/font-spain-regular.woff") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: Ukrain;
  src: url("../fonts/font-ukraine-bold.woff");
  src: url("../fonts/font-ukraine-bold.woff") format("woff"), url("../fonts/font-ukraine-bold.woff") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: Ukrain;
  src: url("../fonts/font-ukraine-regular.woff");
  src: url("../fonts/font-ukraine-regular.woff") format("woff"), url("../fonts/font-ukraine-regular.woff") format("truetype");
  font-weight: 400;
}
@media print {
  *,
  :after,
  :before {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  img {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
@-ms-viewport {
  width: device-width;
}
footer,
header,
nav,
section {
  display: block;
}
body {
  margin: 0;
  // font-family: FilsonPro, Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
body::-webkit-scrollbar {
  width: 0px; /* ширина scrollbar */
}
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
ul {
  margin-bottom: 1rem;
}
ul {
  margin-top: 0;
}
b {
  font-weight: bolder;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
[role="button"],
a,
button,
input:not([type="range"]),
label,
select {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1,
.col-auto {
  -webkit-box-flex: 0;
}
.col-1 {
  -webkit-flex: 0 0 8.3333333333%;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.col-2 {
  -webkit-flex: 0 0 16.6666666667%;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-2,
.col-3 {
  -webkit-box-flex: 0;
}
.col-3 {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -webkit-flex: 0 0 33.3333333333%;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-4,
.col-5 {
  -webkit-box-flex: 0;
}
.col-5 {
  -webkit-flex: 0 0 41.6666666667%;
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.col-6 {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-6,
.col-7 {
  -webkit-box-flex: 0;
}
.col-7 {
  -webkit-flex: 0 0 58.3333333333%;
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.col-8 {
  -webkit-flex: 0 0 66.6666666667%;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-8,
.col-9 {
  -webkit-box-flex: 0;
}
.col-9 {
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -webkit-flex: 0 0 83.3333333333%;
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-10,
.col-11 {
  -webkit-box-flex: 0;
}
.col-11 {
  -webkit-flex: 0 0 91.6666666667%;
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}
.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.3333333333%;
}
.offset-2 {
  margin-left: 16.6666666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.3333333333%;
}
.offset-5 {
  margin-left: 41.6666666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.3333333333%;
}
.offset-8 {
  margin-left: 66.6666666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.3333333333%;
}
.offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-group {
  margin-bottom: 1rem;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.btn:focus,
.btn:hover {
  text-decoration: none;
}
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
  opacity: 0.65;
}
.btn:not([disabled]):not(.disabled):active {
  background-image: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not([disabled]):not(.disabled):active {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.collapse {
  display: none;
}
.collapse.show {
  display: block;
}
.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
.collapsing {
  position: relative;
}
.custom-control {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e9ecef;
}
.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}
.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNiAyLjk3NCA3LjI1IDggMi4xOTN6Jy8+PC9zdmc+);
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA0IDQnPjxwYXRoIHN0cm9rZT0nI2ZmZicgZD0nTTAgMmg0Jy8+PC9zdmc+);
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.navbar {
  position: relative;
  padding: 0.5rem 1rem;
}
.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;

  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-collapse {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: 0 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat 50%;
  background-size: 100% 100%;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg,
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
.p-3 {
  padding: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
}
.py-3 {
  padding-bottom: 1rem !important;
}
.text-center {
  text-align: center !important;
}
.section-title {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 70px;
  color: #0f134c;
}
@media (max-width: 991px) {
  .section-title {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .section-title {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}
.btn {
  font-size: 16px;
  line-height: 20px;
  padding: 19px 40px;
  border-radius: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 60px;
  -webkit-transition: color 0.3s linear 0s, background 0.3s linear 0s, background-color 0.3s linear 0s, -webkit-box-shadow 0.3s linear 0s;
  transition: color 0.3s linear 0s, background 0.3s linear 0s, background-color 0.3s linear 0s, -webkit-box-shadow 0.3s linear 0s;
  -o-transition: color 0.3s linear 0s, background 0.3s linear 0s, background-color 0.3s linear 0s, box-shadow 0.3s linear 0s;
  transition: color 0.3s linear 0s, background 0.3s linear 0s, background-color 0.3s linear 0s, box-shadow 0.3s linear 0s;
  transition: color 0.3s linear 0s, background 0.3s linear 0s, background-color 0.3s linear 0s, box-shadow 0.3s linear 0s,
    -webkit-box-shadow 0.3s linear 0s;
  cursor: pointer;
}
.btn-red.btn-white {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.btn-red.btn-white {
  background: #fff;
  border: none;
  color: #0f134c;
  transition: all 0.2s ease-in-out;
}
.btn-red.btn-white:active,
.btn-red.btn-white:focus,
.btn-red.btn-white:hover {
  opacity: 0.9;
  color: #0f134c;
  outline: none;
  box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
}
.btn-red {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.btn-red,
.btn-red:not([href]):not([tabindex]):not(:disabled) {
  // background: linear-gradient(90deg, #007aff, #81b9f5);
  background: #0f134c;
  border: none;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.btn-red:active,
.btn-red:focus,
.btn-red:hover {
  opacity: 0.9;
  color: #fff;
  outline: none;
  box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
}
.btn-red:disabled,
.btn-red:disabled:active,
.btn-red:disabled:focus,
.btn-red:disabled:hover {
  border-color: transparent;
  opacity: 0.8;
}
.btn-white:not([href]):not([tabindex]):not(:disabled) {
  background: #fff;
  border-color: #fff;
  color: #fa5153;
}
.btn-red-white-outline:not([href]):not([tabindex]):not(:disabled) {
  border-color: #fa5153;
  color: #fff;
  background: 0 0;
}
.btn-red-red-outline:not([href]):not([tabindex]):not(:disabled) {
  border-color: #fa5153;
  color: #fa5153;
  background: 0 0;
}
.btn-white-white-outline:not([href]):not([tabindex]):not(:disabled) {
  border-color: #fff;
  color: #fff;
  background: 0 0;
}
.header-wrap {
  background: linear-gradient(90deg, black, transparent);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
  background: linear-gradient(90deg, black, transparent);
}
@media (max-width: 991px) {
  .header {
    height: 57px;
  }
}
.header .navbar-brand {
  width: 200px;
}
@media (max-width: 991px) {
  .header .navbar-brand {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.header .navbar-brand img {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .header .navbar-brand.mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  .header .navbar-brand.desktop {
    display: none;
  }
}
@media (min-width: 992px) {
  .header .navbar {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .header .navbar {
    background-color: currentColor;
  }
}
@media (min-width: 992px) {
  .header .navbar-nav {
    margin-left: auto;
  }
}
@media (max-width: 991px) {
  .header .navbar-nav {
    padding: 15px 0;
  }
}
@media (min-width: 1200px) {
  .header .navbar-nav .nav-item.offset-left {
    margin-left: 60px;
  }
}
.header .navbar-nav .nav-item .nav-link {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 992px) {
  .header .navbar-nav .nav-item .nav-link {
    color: #fff;
    cursor: pointer;
    -webkit-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .header .navbar-nav .nav-item .nav-link:before {
    color: #fff;
  }
  .header .navbar-nav .nav-item .nav-link:focus,
  .header .navbar-nav .nav-item .nav-link:hover {
    color: hsla(0, 0%, 100%, 0.8);
    text-decoration: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .header .navbar-nav .nav-item .nav-link {
    color: #fff;
    cursor: pointer;
    -webkit-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .header .navbar-nav .nav-item .nav-link:before {
    color: #000;
  }
  .header .navbar-nav .nav-item .nav-link:focus,
  .header .navbar-nav .nav-item .nav-link:hover {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }
}
.header .navbar-toggler .navbar-toggler-icon {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDAsIDAsIDAsIDAuNSknIHN0cm9rZS13aWR0aD0nMicgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+);
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwgMjU1LCAyNTUsIDAuNSknIHN0cm9rZS13aWR0aD0nMicgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+);
}
.footer {
  background-color: #1a1a1a;
}
.footer .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  flex-wrap: wrap;
}
.footer .container > * {
  min-width: 0;
}
@media (min-width: 544px) {
  .footer .container {
    width: 576px;
  }
}
@media (min-width: 768px) {
  .footer .container {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .footer .container {
    width: 940px;
  }
}
@media (min-width: 1200px) {
  .footer .container {
    width: 1140px;
  }
}
@media (min-width: 1300px) {
  .footer .container {
    width: 1280px;
  }
}
@media (max-width: 543px) {
  .footer .container {
    display: block;
  }
}
.footer .logo-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1199px) {
  .footer .logo-col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}
.footer .logo-col .logo-wrap {
  display: inline-block;
  width: 200px;
}
.footer .logo-col .logo-wrap img {
  width: 100%;
}
.footer .logo-col .social-list {
  margin: 0;
}
.footer .logo-col .social-list li {
  display: inline-block;
}
.footer .logo-col .social-list a {
  display: inline-block;
}
.footer .logo-col .social-list a svg {
  width: 21px;
  height: 21px;
  fill: #828282;
}
.footer .logo-col .social-list a svg:hover {
  fill: hsla(0, 0%, 51%, 0.7);
}
@media (max-width: 991px) {
  .footer .logo-col {
    margin-bottom: 20px;
  }
}
.footer ul {
  padding-left: 0;
  list-style: none;
}
.footer .summary {
  color: #b3b3b3;
  background-color: #262626;
  font-size: 14px;
  padding: 40px 0;
}
.footer .summary .container {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  .footer .summary .container {
    display: block;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .partners-section img {
    width: 120px;
  }
}
@media (max-width: 767px) {
  .partners-section img {
    width: 100px;
  }
}
.partners-section .partners-list {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.partners-section .partners-list > * {
  min-width: 0;
}
@media (min-width: 992px) {
  .partners-section .partners-list {
    padding: 0 50px;
  }
}

@media (max-width: 991px) {
  .partners-section .partners-list {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .partners-section .partners-list {
    flex-direction: column;
  }
}
@media (max-width: 543px) {
  .partners-section .partners-list {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.partners-section .partners-list .partners-list-col {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  border-bottom: 1px solid transparent;
  padding-bottom: 10px;
  position: relative;
  transition: all 0.2s ease-out;
}
.partners-section .partners-list .partners-list-col img {
  width: 100%;
}
.partners-section .partners-list .partners-list-col span {
  // font-family: "FilsonPro", Helvetica, sans-serif;
  color: white;
  text-decoration: underline;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;
}
@media (min-width: 768px) {
  .partners-section .partners-list .partners-list-col:hover {
    transform: scale(1.1);
  }
  .partners-section .partners-list .partners-list-col:hover span {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .partners-section .partners-list .partners-list-col.active {
    transform: scale(1.2);
  }
  .partners-section .partners-list .partners-list-col.active span {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .partners-section .partners-list .partners-list-col {
    justify-content: center;
    width: 190px;
    height: 190px;
  }
}
@media (max-width: 820px) {
  .partners-section .partners-list .partners-list-col {
    width: 170px;
    height: 170px;
  }
}

@media (max-width: 768px) {
  .partners-section .partners-list .partners-list-col {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
}
.hero-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 600px;
}
.hero-section > * {
  min-width: 0;
}
.hero-section.home {
  background-image: url(../images/background/background_slide_3.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: background 1s ease-in-out 0.5s;
}
@media (min-width: 768px) {
  .hero-section.home .img-column {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-section.home .img-column {
    margin-bottom: -50px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}
@media (max-width: 767px) {
  .hero-section.home .img-column {
    text-align: center;
    margin-bottom: 40px;
  }
}
.hero-section.home .img-column .image {
  width: 600px;
}
@media (max-width: 767px) {
  .hero-section.home .img-column .image {
    width: 370px;
  }
}
@media (min-width: 992px) {
  .hero-section.home .content-column {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 700px;
    -ms-flex: 0 0 700px;
    flex: 0 0 700px;
  }
}
@media (max-width: 991px) {
  .hero-section.home {
    padding-top: 57px;
  }
}
@media (min-width: 768px) {
  .hero-section.home .content-column {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
}
.hero-section.feature {
  background: url(../images/background/feature-hero-bg.svg) no-repeat 0 100%;
  background-size: cover;
}
@media (min-width: 768px) {
  .hero-section.feature .container {
    max-width: 100%;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .hero-section.feature {
    margin-bottom: 150px;
  }
}
@media (min-width: 1200px) {
  .hero-section.feature .img-column {
    margin-bottom: -200px;
  }
}
@media (min-width: 768px) {
  .hero-section.feature .img-column {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-section.feature .img-column {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}
@media (max-width: 767px) {
  .hero-section.feature .img-column {
    text-align: center;
    margin-bottom: 40px;
  }
}
.hero-section.feature .img-column .image {
  max-width: 100%;
  width: 100%;
}
@media (max-width: 767px) {
  .hero-section.feature .img-column .image {
    width: 400px;
  }
}
@media (min-width: 992px) {
  .hero-section.feature .content-column {
    width: 41.6666666667%;
    min-width: 0;
    margin-left: 8.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 540px;
    padding-right: 50px;
  }
}
@media (min-width: 768px) {
  .hero-section.feature .img-column {
    max-width: 55%;
  }
}
@media (min-width: 768px) {
  .hero-section.feature .img-column.cropped {
    overflow: hidden;
  }
  .hero-section.feature .img-column.cropped .cropped-image {
    width: 170%;
  }
}
@media (min-width: 768px) {
  .hero-section {
    padding: 100px 0 0;
  }
}
@media (max-width: 767px) {
  .hero-section {
    padding: 80px 0 0px;
  }
}
.hero-section.full {
  min-height: 100vh;
}
.hero-section .inner-wrap,
.hero-section .wrap {
  width: 100%;
}
@media (min-width: 768px) {
  .hero-section .inner-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .hero-section .inner-wrap > * {
    min-width: 0;
  }
}
.hero-section .category {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: hsla(0, 0%, 100%, 0.5);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.hero-section .category,
.hero-section .title {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.hero-section .title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .hero-section .title {
    font-size: 32px;
    line-height: 38px;
  }
}
@media (max-width: 767px) {
  .hero-section .title {
    font-size: 26px;
    line-height: 32px;
  }
}
.hero-section .subtitle {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
}
.hero-section .subtitle,
.hero-section .title {
  color: #fff;
}
.hero-section .buttons-wrap > * {
  margin-bottom: 20px;
  min-width: 170px;
}
.hero-section .scroll-icon {
  width: 100%;
}
.hero-section .scroll-icon .icon {
  display: block;
  width: 20px;
  height: 30px;
  border-radius: 10px;
  border: 2px solid #3dc47f;
  position: relative;
}
.hero-section .scroll-icon .icon:after {
  content: "";
  display: block;
  top: 5px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 2px;
  height: 8px;
  border-radius: 2px;
  background-color: #3dc47f;
  -webkit-animation: mouse-wheel 2s ease infinite;
  animation: mouse-wheel 2s ease infinite;
}
@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    top: 5px;
  }
  50% {
    opacity: 1;
    top: 5px;
  }
  to {
    opacity: 0;
    top: 15px;
  }
}
@keyframes mouse-wheel {
  0% {
    opacity: 1;
    top: 5px;
  }
  50% {
    opacity: 1;
    top: 5px;
  }
  to {
    opacity: 0;
    top: 15px;
  }
}
@media (min-width: 992px) {
  .features-list-section {
    padding: 20px 0 30px;
  }
  .wide-section {
    margin-top: 100px;
  }
}
@media (min-width: 544px) and (max-width: 991px) {
  .features-list-section {
    padding: 20px 0 40px;
  }
}
@media (max-width: 543px) {
  .features-list-section {
    padding: 40px 0 30px;
  }
}
.features-list .features-list-col {
  padding: 0 50px 50px;
}

.features-list .features-list-item {
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  text-align: center;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  z-index: 0;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.features-list .features-list-item p {
  color: #0f134c;
  opacity: 0.5;
  margin-bottom: 0;
}
@media (min-width: 544px) {
  .features-list .features-list-item {
    padding: 20px 15px 35px;
  }
}
@media (max-width: 767px) {
  .features-list .features-list-item {
    padding: 20px 10px 15px;
  }
}
// .features-list .features-list-item:hover {
//   -webkit-box-shadow: 0 5px 14px rgba(38, 55, 89, 0.15);
//   box-shadow: 0 5px 14px rgba(38, 55, 89, 0.15);
// }
.features-list .features-list-item:hover:after {
  opacity: 1;
}
.features-list .features-list-item,
.features-list .features-list-item:focus,
.features-list .features-list-item:hover {
  text-decoration: none;
}
.features-list .features-list-item .icon-wrap {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 15px;
  overflow: hidden;
  border-radius: 20px;
}
.features-list .features-list-item .icon-wrap img {
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .features-list .features-list-item .icon-wrap {
    zoom: 0.8;
    margin-bottom: 15px;
  }
}
.features-list .features-list-item .feature-title {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #0f134c;
  display: block;
  min-height: 56px;
}
@media (max-width: 767px) {
  .features-list .features-list-item .feature-title {
    // font-family: FilsonPro, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }
}
.perfect-solution-section {
  /* background: url(../images/background/perfect-solution-section-bg.svg) top no-repeat; */
  background: url(../images/background/background_2.jpg) top no-repeat;

  background-size: cover;
}
.perfect-solution-section .section-title {
  color: #fff;
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .perfect-solution-section .section-title {
    margin-bottom: 100px;
  }
}
.perfect-solution-wrap {
  background-color: rgba(0, 0, 0, 0.4);
}
@media (min-width: 1200px) {
  .perfect-solution-wrap {
    padding: 150px 0;
  }
}
@media (max-width: 1199px) {
  .perfect-solution-wrap {
    background-size: 1200px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .perfect-solution-wrap {
    padding: 100px 0;
  }
}
@media (max-width: 767px) {
  .perfect-solution-wrap {
    padding: 50px 0 50px;
  }
}
@media (max-width: 543px) {
  .get-started-section {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .get-started-section .content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .get-started-section .content-wrap > * {
    min-width: 0;
  }
}
.get-started-section .content-wrap .content-col {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 30px 0;
}
@media (min-width: 992px) {
  .get-started-section .content-wrap .content-col {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    padding-right: 20px;
  }
}
@media (max-width: 991px) {
  .get-started-section .content-wrap .content-col {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}
.get-started-section .content-wrap .image-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
@media (max-width: 767px) {
  .get-started-section .content-wrap .image-col {
    display: none;
  }
}
.get-started-section .content-wrap .image-col .image-wrap {
  position: relative;
  overflow: hidden;
}
.get-started-section .content-wrap .image-col .image-wrap img {
  top: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}
@media (min-width: 1200px) {
  .get-started-section .content-wrap .image-col .image-wrap {
    width: 512px;
    height: 410px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .get-started-section .content-wrap .image-col .image-wrap {
    width: 460px;
    height: 350px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .get-started-section .content-wrap .image-col .image-wrap {
    width: 200px;
    height: 150px;
  }
}
.get-started-section .title-wrap {
  margin-bottom: 40px;
}
.get-started-section .subtitle,
.get-started-section .title {
  color: #0f134c;
  font-size: 24px;
  font-weight: 700;
}
.get-started-section .title {
  margin-bottom: 15px;
}
.get-started-section .subtitle {
  font-weight: 400;
  opacity: 0.5;
  margin-bottom: 40px;
}
.get-started-section .buttons-wrap > * {
  margin-bottom: 20px;
  min-width: 215px;
  margin-right: 8px;
  margin-left: 8px;
}
@media (min-width: 544px) {
  .get-started-section .buttons-wrap > :first-child {
    margin-left: 0;
  }
}
.custom-control .custom-control-indicator {
  cursor: pointer;
  width: 20px;
  height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-control .custom-control-description {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  line-height: 24px;
  padding-top: 3px;
  padding-left: 5px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #2787f7;
  background-color: #2787f7;
}
.custom-checkbox .custom-control-input:disabled ~ .custom-control-indicator {
  border: 2px solid #ccc;
  background-color: #ccc;
}
.custom-checkbox .custom-control-indicator {
  background-color: transparent;
  border: 2px solid #ccc;
  background-size: 14px;
}
.currency-switch .btn:not([href]):not([tabindex]):not(:disabled) {
  background: #fff;
  border-color: #4c8aaf;
  color: #0f134c;
}
.currency-switch .btn.active-btn:not([href]):not([tabindex]):not(:disabled) {
  background: #2787f7;
  border-color: #2787f7;
  color: #fff;
}
.about-section {
  background-image: url(../images/background/about-us-background.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
}
@media (max-width: 768px) {
  .about-section {
    background-position: bottom right;
    background-size: cover;
  }
}
@media (min-width: 992px) {
  .section {
    padding: 80px 0;
  }
}
@media (max-width: 991px) {
  .section {
    padding: 40px 0;
  }
}
@media (min-width: 768px) {
  .about-section .image-col.second-order {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
}
@media (max-width: 767px) {
  .about-section .image-col {
    margin-bottom: 30px;
  }
  .about-section .image-col img {
    width: 300px;
  }
}
.about-section .title {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: #0f134c;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .about-section .title {
    font-size: 32px;
    line-height: 38px;
    color: #0f134c;
  }
}
@media (max-width: 767px) {
  .about-section .title {
    font-size: 26px;
    line-height: 32px;
    color: #0f134c;
  }
}
.about-section .subtitle {
  // font-family: FilsonPro, Helvetica, sans-serif;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: #0f134c;
  margin-bottom: 50px;
}
.about-section .text {
  // font-family: FilsonPro, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: gray;
  margin-bottom: 20px;
}
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0;
}
body {
  // font-family: FilsonPro, Helvetica, sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
@media (min-width: 1500px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1900px) {
  body {
    zoom: 1.3;
  }
}
@media (min-width: 2400px) {
  body {
    zoom: 1.6;
  }
}
@media (min-width: 2800px) {
  body {
    zoom: 1.8;
  }
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  justify-content: center;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.popup-container.active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.login-validation-wrap {
  position: relative;
  overflow-y: auto;
  box-shadow: 0 0 30px black;
}
.login-validation-wrap::-webkit-scrollbar {
  width: 0;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 25px;
  height: 25px;
  padding: 0;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
}
.close-btn:active,
.close-btn:focus {
  outline: none;
}

.close-btn span {
  position: absolute;
  top: 13px;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  transition: transform 0.2s ease-in-out;
}

.close-btn span:first-child {
  transform: rotate(45deg);
}
.close-btn span:last-child {
  transform: rotate(-45deg);
}

.close-btn:hover {
  transform: scale(1.1);
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  background-color: white;
  opacity: 0.5;
}

.form-signin {
  max-width: 450px;
  padding: 30px;
  background: #fff;
  box-shadow: 0 10px 70px 0 rgba(108, 158, 195, 0.25);
}
.form-signin .form-signin-heading {
  font-size: 16px;
}
.form-signin .checkbox,
.form-signin .form-signin-heading {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 8px 20px;
  font-size: 13px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin .brand {
  width: 240px;
  max-width: 100%;
  margin: 0 auto 30px;
}
.form-signin .brand img,
.form-signin .brand svg {
  max-width: 100%;
}
.brand {
  margin-bottom: 50px;
  display: block;
}
html {
  scroll-behavior: smooth;
}

.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.our-mission__header {
  text-align: center;
  border: 1px solid #0f134c;
  border-radius: 24px;
}
@media (max-width: 991px) {
  .our-mission__header {
    width: 90%;
    margin: auto;
  }
}
.our-mission__header-tab {
  cursor: pointer;
  border-radius: 24px;
  padding: 10px 0;
  transition: background 0.4s ease-in-out;
  color: #0f134c;
}
.our-mission__header-tab.active {
  background-color: #0f134c;
  color: white;
}
.our-mission__content-wrap {
  position: relative;
  flex-wrap: nowrap;
  overflow: hidden;
}
.our-mission__content {
  position: relative;
  flex-wrap: nowrap;
  transition: all 0.4s ease-in-out;
}
.our-mission__content-tab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
  width: 100%;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.our-mission__content-text {
  text-align: center;
  margin-right: 2rem;
}
.our-mission__content-image {
  display: flex;
  justify-content: center;
  width: 400px;
  height: 300px;
}
.our-mission__content img {
  width: inherit;
  height: inherit;
  border-radius: 20px;
}
@media (max-width: 991px) {
  .our-mission__content-tab {
    flex-direction: column;
    align-items: center;
  }
  .our-mission__content-text {
    margin-right: 0;
  }
  .our-mission__content-image {
    display: flex;
    justify-content: center;
    width: 300px;
    height: 250px;
  }
}
.our-mission__content-tab.active {
  opacity: 1;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.abous-ut__text {
  text-align: center;
}
.contacts {
  background-image: url(../images/background/about-us-background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contacts__content {
  text-align: center;
  position: relative;
}

.contacts__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.8s ease-in-out;
}

.contacts__info.active {
  opacity: 0;
}

.contacts__company {
  margin-bottom: 35px;
}

.contacts__address {
  margin-bottom: 35px;
}

.contacts__link {
  display: flex;
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .contacts__company {
    font-size: 16px;
  }

  .contacts__address {
    font-size: 14px;
  }

  .contacts__link {
    font-size: 14px;
  }
}

.contacts__link:last-of-type {
  margin-bottom: 35px;
}

.contacts__link a {
  color: #212529;
  transition: all 0.2s ease;
}

.contacts__link a:hover {
  text-decoration: none;
  transform: scale(1.1);
}

.contacts__form {
  position: absolute;
  top: 0;
  width: 100%;
  height: 110%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-100%);
  z-index: 1;
  transition: all 0.8s ease-in-out;
}

.contacts__form.active {
  transform: translateX(0);
}

.contacts__form input,
.contacts__form textarea {
  width: 100%;
  max-width: 500px;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid gray;
}

.contacts__form input {
  margin-bottom: 20px;
}

.contacts__form textarea {
  resize: none;
}

@media (max-width: 991px) {
  .contacts__form input,
  .contacts__form textarea {
    width: 90%;
    font-size: 12px;
  }
}

.contacts__btns {
  display: flex;
  margin-top: 15px;
}

.btn-cancel {
  background-color: transparent;
  text-decoration: underline;
  border: none;
  margin-left: 15px;
  outline: none;
}

.swiper-button-prev,
.swiper-button-next {
  opacity: 0 !important;
  transition: all 0.4s ease-in-out;
}
.swiper-pagination {
  height: 0 !important;
}

@media (max-width: 991px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  .swiper-pagination {
    height: auto !important;
  }
}

.features-list:hover .swiper-button-prev {
  opacity: 1 !important;
}

.features-list:hover .swiper-button-next {
  opacity: 1 !important;
}
